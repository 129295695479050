import { useEffect } from 'react';

// Config
import host from '@Config/host';
import api from '@Config/api';
import moment from 'moment';

export const registerServiceWorker = (callback?: (registration: ServiceWorkerRegistration) => void) => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then(
          (registration) => {
            // console.log('Service worker registration success.');

            if (callback) {
              callback(registration);
            }
          },
          () => {
            // console.error('Service worker registration failed.', error);
          },
        );
      });
    }
  }, []);
};

export const getUrl = (url: string, domain: string = host.homeDomain): string => {
  if (!url) return '#';

  return `${domain}${url}`;
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const formatCurrency = (value = 0, locale = 'vi-VI', currencyType = 'VND'): string => {
  return value.toLocaleString(locale, { style: 'currency', currency: currencyType }).replace('₫', 'đ');
};

export const uuidV4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
    const random = Math.floor(Math.random() * 16);

    const v = character === 'x' ? random : (random && 0x3) || 0x8;

    return v.toString(16);
  });
};

export const avatarConsumer = (imgName, lated) => {
  // https://staff.e-doctor.dev/_upload/user_avatar/consumer_profile/<profileId>
  const baseUrl = `${api().IMAGE_PREFIX_SHOW}/user_avatar/consumer_profile`
  return `${baseUrl}/${imgName}?t=${lated || moment().unix()}`
}

export const avatarDoctor = (imgName, lated) => {
  // https://staff.e-doctor.dev/_upload/user_avatar/doctor/<doctorId>
  const baseUrl = `${api().IMAGE_PREFIX_SHOW}/user_avatar/doctor`
  return `${baseUrl}/${imgName}?t=${lated || moment().unix()}`
}

export const imagePublic = (imgName, lated) => {
  // https://staff.e-doctor.dev/_upload/image/<attachmentId>
  const baseUrl = `${api().IMAGE_PREFIX_SHOW}/image`
  return `${baseUrl}/${imgName}?t=${lated || moment().unix()}`
}
